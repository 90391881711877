<template>
  <div class="yield_info_box">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="200px"
      class="demo-ruleForm"
    >
      <div class="basic_title">生产许可信息</div>
      <el-form-item label="食品生产许可证编号" prop="name">
        <span class="black" v-if="lock">{{ ruleForm.name }}</span>
        <el-input
          v-else
          v-model="ruleForm.name"
          placeholder="食品生产许可证编号"
        ></el-input>
      </el-form-item>
      <el-form-item label="食品生产许可证" prop>
        <div class="up_img_hint">
          请上传最新的食品生产许可证，包含正本、副本以及品种明细表。
          <br />
          <div class="fl">格式要求：</div>
          <div class="hint">
            原件照片、扫描件或者加盖公章的复印件，支持.jpg .jpeg .bmp
            .png格式图片，每张图片大小不超过10M，最多不超过3张。
          </div>
        </div>
        <div class="up_img_list clr">
          <div class="up_img_li">
            <up-img :img_state="2" :img_type="2" />
            <div class="text">正本/副本</div>
          </div>
          <div class="up_img_li">
            <up-img :img_state="3" :img_type="2" />
            <div class="text">品种明细</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="食品生产许可证其他图片" prop="moreFiles">
        <UpFile
          tip="最多可上传 10份 图片，图片最大支持 10M，"
          :maxSize="10"
          v-model="ruleForm.moreFiles"
          accept="image/jpeg, image/jpg, image/png, image/bmp"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import UpImg from "./up_img";
import UpFile from "./upFile.vue";
export default {
  props: ["yield_list"],
  components: {
    UpImg,
    UpFile,
  },

  data() {
    return {
      ruleForm: {
        name: "",
        moreFiles: undefined,
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入食品生产许可证编号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    lock() {
      return this.$store.state.common.apply;
    },
  },
  methods: {
    async submitForm() {
      await this.$refs["ruleForm"].validate();
      return this.ruleForm;
    },
    resetForm() {
      this.$refs["ruleForm"].resetFields();
    },
    // 图片上传
  },
  mounted() {
    setTimeout((res) => {
      let list = this.$store.getters.firm_list;
      if (list) {
        this.ruleForm = {
          name: list.productionLicense.productionLicenseNumber,
          moreFiles: list.productionLicense.moreFiles,
        };
      }
    }, 0);
  },
};
</script>

<style lang="scss" scoped>
.yield_info_box {
  .basic_title {
    font-size: 16px;
    font-weight: bold;
    line-height: 50px;
  }
  .el-upload__tip {
    margin-top: 0;
  }
  .up_img_list {
    margin-top: 20px;
    .up_img_li {
      margin-right: 20px;
      float: left;
      .text {
        font-size: 13px;
        text-align: center;
        color: #f56c6c;
        font-weight: bold;
      }
    }
  }
  .up_img_hint {
    font-size: 12px;
    color: #606266;
    line-height: 1.5;
    .hint {
      padding-left: 60px;
    }
  }
}
</style>
