<template>
  <div class="venify_admin">
    <div class="input">
      <span class="title">管理员注册手机号：</span>
      <el-input v-model="user.mobile" disabled>
        <template slot="prepend">+86</template>
      </el-input>
    </div>
    <div class="wx_code">
      <span class="title">管理员微信扫码：</span>
      <div class="code">
        <div id="app_wx"></div>
      </div>
    </div>
    <div class="code_hint">
      为了验证您的身份，请在确认上述
      <span>管理员注册手机号码</span>无误后用管理员微信扫描二维码
    </div>
    <div class="hint_btn">
      <span class="text">若手机号码或微信号无法使用，请点此</span>
      <router-link to="/usermod">
        <el-button size="small" type="primary" round>重置绑定账号</el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  computed: {
    ...mapGetters(["user"])
  },
  data() {
    return {
      poll_status: "",
      code: ""
    };
  },
  mounted() {
    this.$emit("agree", 3);
    let that = this;
    that.$api.oldweixinconfig().then(res => {
      let obj = new WxLogin({
        self_redirect: true,
        id: "app_wx",
        appid: res.data.appid,
        scope: res.data.scope,
        redirect_uri: res.data.redirect_uri, 
        state: res.data.state,
        style: "black",
        href:
          "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7DQogIHdpZHRoOiAxNTBweDsNCiAgbWFyZ2luOiAwOw0KfQ0KDQouaW1wb3dlckJveCB7DQogIGxpbmUtaGVpZ2h0OiAwOw0KICBmb250LXNpemU6IDA7DQogIHdpZHRoOiBhdXRvOw0KICB0ZXh0LWFsaWduOiBjZW50ZXI7DQogIHdpZHRoOiAxMDAlOw0KICANCn0NCg0KYm9keSB7DQogIG1hcmdpbjogMDsNCn0NCg0KLmltcG93ZXJCb3ggLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCg0KLmltcG93ZXJCb3ggLmluZm8gew0KICB3aWR0aDogMTUwcHg7DQogIGRpc3BsYXk6IG5vbmU7DQp9DQoNCi5zdGF0dXNfaWNvbiB7DQogIGRpc3BsYXk6IG5vbmUNCn0NCg0KLmltcG93ZXJCb3ggLnN0YXR1cyB7DQogIHRleHQtYWxpZ246IGNlbnRlcjsNCn0NCg=="
      });
      new Promise(resolve => {
        this.code = res.data.state;
        resolve();
      }).then(res => {
        setTimeout(() => {
          that.poll();
        }, 1000);
      });
    });
  },
  methods: {
    poll() {
      let that = this;
      let info = {
        scanCode: this.code
      };
      that.$api.scanCode(info).then(res => {
        if (res.errcode === 0) {
          console.log("扫码授权成功");
          clearTimeout(that.poll_status);
          this.$emit("agree", 3);
        } else {
          that.poll_status = setTimeout(() => {
            console.log("定时器没关闭");
            that.poll();
          }, 1000);
        }
      });
    },
    off() {
      clearTimeout(this.poll_status);
      this.poll_status = null;
    }
  },
  destroyed() {
    clearTimeout(this.poll_status);
    this.poll_status = null;
  }
};
</script>

<style lang="scss" scoped>
.venify_admin {
  .title {
    font-size: 16px;
    font-weight: bold;
    line-height: 80px;
  }
  .wx_code {
    .title {
      font-size: 18px;
    }
    .code {
      height: 180px;
    }
  }
  .code_hint {
    font-size: 13px;
    span {
      font-weight: bold;
      text-align: center;
    }
  }
  .hint_btn {
    margin-top: 20px;
    font-size: 13px;
    .el-button {
      color: #000;
      font-weight: bold;
    }
  }
}
</style>
