<template>
  <div class="basic_info_box">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="200px"
      class="demo-ruleForm"
    >
      <div class="basic_type">
        <el-form-item label="选择企业类型" prop="region">
          <el-tooltip placement="right">
            <div slot="content">
              取得食品生产许可证的企业均为生产型企业，
              <br>其余情况均视为非生产型企业。
            </div>
            <i class="el-icon-question"></i>
          </el-tooltip>
          <span class="black" v-if="lock">{{ruleForm.region}}</span>
          <el-select v-else v-model="ruleForm.region" placeholder="选择企业类型">
            <el-option label="生产型企业" value="生产型企业"></el-option>
            <el-option label="非生产型企业" value="非生产型企业"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="basic_title">企业基本资料</div>
      <el-form-item label="企业全称" prop="firm_name">
        <span class="black" v-if="lock">{{ruleForm.firm_name}}</span>
        <el-input v-else v-model="ruleForm.firm_name" placeholder="请输入企业全称"></el-input>
        <!-- 哈哈哈 -->
      </el-form-item>
      <el-form-item label="纳税人识别号" prop="itin">
        <span class="black" v-if="lock">{{ruleForm.itin}}</span>
        <el-input v-else v-model="ruleForm.itin" placeholder="请输入纳税人识别号"></el-input>
      </el-form-item>
      <el-form-item label="认证联系人真实姓名" prop="name">
        <span class="black" v-if="lock">{{ruleForm.name}}</span>
        <el-input v-else v-model="ruleForm.name" placeholder="请输入认证联系人真实姓名"></el-input>
      </el-form-item>
      <el-form-item label="认证联系人电话" prop="tell">
        <span class="black" v-if="lock">{{ruleForm.tell}}</span>
        <el-input v-else v-model="ruleForm.tell" placeholder="请输入认证联系人电话"></el-input>
      </el-form-item>
      <el-form-item label="工商营业执照">
        <div class="up_img_hint">
          请上传最新的营业执照。
          <br>
          <div class="fl">格式要求：</div>
          <div class="hint">原件照片、扫描件或者加盖公章的复印件，支持.jpg .jpeg .bmp .gif .png格式图片，大小不超过10M。</div>
        </div>
        <div class="up_img_list clr">
          <div class="up_img_li">
            <up-img :img_state="0" :img_type="1" :photo='ruleForm.img' ></up-img>
            <div class="text">工商营业执照</div>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import UpImg from "./up_img";
export default {
  props: ["basic_list"],
  components: {
    UpImg
  },
  data() {
    return {
      ruleForm: {
        name: "",
        region: "",
        firm_name: "",
        itin: "",
        tell: "",
        img:''
      },
      rules: {
        region: [
          { required: true, message: "请选择活动区域", trigger: "change" }
        ],
        name: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
          { min: 2, message: "请输入2个字符以上姓名", trigger: "blur" }
        ],
        firm_name: [
          { required: true, message: "请输入企业全称", trigger: "blur" },
          { min: 4, message: "长度不得少于4个字符", trigger: "blur" }
        ],
        itin: [
          { required: true, message: "请输入纳税人识别号", trigger: "blur" }
        ],
        tell: [{ required: true, message: "请输入联系人电话", trigger: "blur" }]
      }
    };
  },
  computed: {
    lock() {
      return this.$store.state.common.apply;
    },
    firm_list() {
      return this.$store.getters.firm_list;
    }
  },
  watch: {
    ruleForm: {
      handler(val) {
        if (val.region === "生产型企业") {
          this.$emit("type", true);
        } else {
          this.$emit("type", false);
        }
      },
      deep: true
    },
  },
  methods: {
    submitForm() {
      let state;
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          state = true;
        } else {
          state = false;

          return false;
        }
      });
      let info = {
        arr: this.ruleForm,
        state: state
      };
      return info;
    },
    resetForm() {
      this.$refs["ruleForm"].resetFields();
    },
    // 图片上传
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      let fileName = file.name;
      let regex = /(.jpg|.jpeg|.png|.bmp)$/;
      if (regex.test(fileName.toLowerCase())) {
        const isLt5M = file.size / 1024 / 1024 < 10;
        if (!isLt5M) {
          this.$message.error("图片大小不能超过 10MB!");
          return false;
        }
        return file.type && isLt5M;
      } else {
        this.$message.error("请选择正确图片文件");
        return false;
      }
    },
    list() {
      // "上传完成后清空"
      this.$refs.upload.clearFiles();
    }
  },
  mounted() {
    setTimeout(res => {
      let list = this.$store.getters.firm_list;
      if (list) {
        this.ruleForm = {
          region: list.companyType === 0 ? "生产型企业" : "非生产型企业",
          firm_name: list.companyName,
          itin:list.taxNo,
          name:list.contactName ,
          tell:list.contactMobile ,
          img:list.businessLicense 
        };
      }
    }, 0);
  }
};
</script>

<style lang="scss" >
.basic_info_box {
  .up_img_hint {
    font-size: 12px;
    color: #606266;
    line-height: 1.5;
    .hint {
      padding-left: 60px;
    }
  }
  .up_img_list {
    margin-top: 20px;
    .up_img_li {
      float: left;
      .text {
        font-size: 13px;
        text-align: center;
        color: #f56c6c;
        font-weight: bold;
      }
    }
  }
  .basic_type {
    .el-form-item__content {
      color: #f2c811;
    }
    .el-form-item__label {
      width: 180px !important;
    }
    .el-form-item__error {
      left: 30px;
    }
    .el-form-item__content {
      margin-left: 170px !important;
      font-size: 16px;
    }
  }
  .basic_title {
    font-size: 16px;
    font-weight: bold;
    line-height: 50px;
  }

  //   图片上传
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background: #ffffff;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .el-upload__tip {
    margin-top: 0;
  }
}
</style>
