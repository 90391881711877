<template>
  <div class="basic_info_box">
    <el-form ref="ruleForm" label-width="200px" class="demo-ruleForm">
      <el-form-item label="食品经营许可证">
        <div class="up_img_hint">
          请上传最新的食品经营许可证。
          <br>
          <div class="fl">格式要求：</div>
          <div class="hint">原件照片、扫描件或者加盖公章的复印件，支持.jpg .jpeg .bmp .png格式图片，大小不超过10M。
          </div>
        </div>
        <div class="up_img_list clr">
          <div class="up_img_li">
            <up-img :img_state="4" :img_type="9"></up-img>
            <div class="text">食品经营许可证</div>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import UpImg from "./up_img";
export default {
  props:['food_list'],
  components: {
    UpImg
  },
};
</script>

<style lang="scss" >
.basic_info_box {
  .up_img_hint {
    font-size: 12px;
    color: #606266;
    line-height: 1.5;
    .hint {
      padding-left: 60px;
    }
  }
  .up_img_list {
    margin-top: 20px;
    .up_img_li {
      float: left;
      .text {
        font-size: 13px;
        text-align: center;
        color: #f56c6c;
        font-weight: bold;
      }
    }
  }
  .basic_type {
    .el-form-item__content {
      color: #f2c811;
    }
    .el-form-item__label {
      width: 180px !important;
    }
    .el-form-item__error {
      left: 30px;
    }
    .el-form-item__content {
      margin-left: 170px !important;
      font-size: 16px;
    }
  }
  .basic_title {
    font-size: 16px;
    font-weight: bold;
    line-height: 50px;
  }

  //   图片上传
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background: #ffffff;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .el-upload__tip {
    margin-top: 0;
  }
}
</style>
