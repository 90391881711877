<template>
  <div class="up_img_box">
    <div class="flex-img">
      <div
        class="el-upload-list el-upload-list--picture-card"
        v-show="hideShow"
      >
        <div class="el-upload-list__item is-success">
          <img class="flex-img__image" :src="image" />
          <label class="el-upload-list__item-status-label">
            <i class="el-icon-upload-success el-icon-check"></i>
          </label>
          <span class="el-upload-list__item-actions" v-if="!lock">
            <span class="el-upload-list__item-delete">
              <i class="el-icon-delete" @click.stop="handleRemove()"></i>
            </span>
          </span>
        </div>
      </div>
      <el-upload
        class="image-uploader"
        :action="uploadUrl"
        :show-file-list="false"
        accept="image/jpeg, image/jpg, image/png, image/bmp"
        :on-success="imageUploadSuccess"
        :before-upload="beforeUpload"
        v-show="!hideShow"
        list-type="picture-card"
        :on-remove="handleRemove"
        :data="datas"
        :http-request="uploadRequest"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { upload } from "@/assets/js/validate";
export default {
  props: ["img_state", "img_type", "photo"],
  data() {
    return {
      datas: {
        type: this.img_type,
      },
      image: false,
      uploadUrl: "https://upload.qiniup.com/",
      file: this.image ? this.image : "",
      url: "",
    };
  },
  watch: {
    image(value) {
      this.file = value;
    },
    file(value) {
      const list = value;
      if (value.data) {
        let info = {
          url: {
            filename: value.data.filename,
            originalname: value.data.originalname,
            url: value.data.url,
          },
          id: this.img_state,
        };
        this.$store.commit("set_firm", info);
        this.image = value.data.url;
      }
    },
  },
  computed: {
    hideShow() {
      //当图片多于一张的时候，就隐藏上传框
      return this.file === "" ? false : true;
    },
    lock() {
      return this.$store.state.common.apply;
    },
    ...mapGetters(["user"]),
  },

  methods: {
    imageUploadSuccess(response) {
      this.file = response;
    },
    // 营业执照大小设置
    beforeUpload(file) {
      const imageSize = file.size / 1024 / 1024 < 10;
      if (!imageSize) {
        this.$message.error("上传文件大小不能超过 10MB!");
        return false;
      }
      return imageSize;
    },
    handleRemove() {
      this.file = "";
      this.$store.commit("set_firm", {
        url: "",
        id: this.img_state,
      });
    },
    handlePictureCardPreview(file) {
      this.image = file.url;
    },
    uploadRequest(request) {
      request.user = this.user;
      request.type = this.img_type;
      let that = this;
      that.$api
        .upload_get({
          type: request.type,
        })
        .then((res) => {
          let token = res.data.token;
          let host = res.data.domain;
          upload(
            token,
            request,
            (next) => {
              let total = next.total;
            },
            (error) => {
              console.log(error);
            },
            (complete) => {
              let hash = complete.hash;
              let key = complete.key;
              let data = {
                data: complete,
              };
              that.imageUploadSuccess(data);
            }
          );
        });
    },
  },
  mounted() {
    let that = this;
    setTimeout((res) => {
      let list = this.$store.getters.firm_list;
      if (list) {
        let info = {
          id: that.img_state,
        };
        if (list.companyType === 1) {
          switch (that.img_state) {
            case 0:
              info.url = list.businessLicense;
              that.$store.commit("set_firm", info);
              that.image = list.businessLicense.url;
              break;
            case 4:
              info.url = list.foodBusinessLicense;
              that.$store.commit("set_firm", info);
              that.image = list.foodBusinessLicense.url;
              break;
          }
        } else {
          switch (that.img_state) {
            case 0:
              info.url = list.businessLicense;
              that.$store.commit("set_firm", info);
              that.image = list.businessLicense.url;
              break;
            case 1:
              info.url = list.productionLicense.productionLicenseOriginal;
              that.$store.commit("set_firm", info);
              that.image = list.productionLicense.productionLicenseOriginal.url;
              break;
            case 2:
              info.url = list.productionLicense.productionLicenseCopy;
              that.$store.commit("set_firm", info);
              that.image = list.productionLicense.productionLicenseCopy.url;
              break;
            case 3:
              info.url = list.productionLicense.productionLicenseDetail;
              that.$store.commit("set_firm", info);
              that.image = list.productionLicense.productionLicenseDetail.url;
              break;
          }
        }
      }
    }, 0);
  },
};
</script>
<style lang="scss">
.up_img_box {
  .flex-img__image {
    width: 100%;
    height: 100%;
  }

  .flex-img {
    line-height: 0;
    .el-upload-list--picture-card .el-upload-list__item {
      margin: auto;
      width: 150px;
      height: 200px;
      line-height: 0;
    }
  }

  .el-upload--picture-card {
    width: 150px;
    height: 200px;
    line-height: 200px;
  }
}
</style>

 