<template>
  <div class="process_box">
    <el-row>
      <el-col :span="4">
        <div class="process_li active">
          <div>
            <span class="icon dib tac">1</span>
            <span class="text">{{name_list.one}}</span>
          </div>
        </div>
      </el-col>
      <el-col :span="2">
        <div class="process_icon tac">
          <span class="iconfont iconjiantou tac"></span>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="process_li" :class="active>2? 'active':''">
          <div>
            <span class="icon dib tac">2</span>
            <span class="text">{{name_list.two}}</span>
          </div>
        </div>
      </el-col>
      <el-col :span="2">
        <div class="process_icon tac">
          <span class="iconfont iconjiantou tac"></span>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="process_li" :class="active>3? 'active':''">
          <div>
            <span class="icon dib tac">3</span>
            <span class="text">{{name_list.three}}</span>
          </div>
        </div>
      </el-col>
      <el-col :span="2">
        <div class="process_icon tac">
          <span class="iconfont iconjiantou tac"></span>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="process_li" :class="active>4? 'active':''">
          <div>
            <span class="icon dib tac">4</span>
            <span class="text">{{name_list.four}}</span>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import headTop from "@/components/head";
export default {
  props:['name_list'],
  components: {
    // headTop
  },

  data() {
    return {
      active: 1,
    };
  },
  mounted() {
    //   获取传递过来的参数
    // let id = this.$route.query.id;
    // that.$api
    //   .GetIndexList()
    //   .then(res => {})
  },
  methods: {
    nexts(e) {
      this.active = e + 1;
    }
  }
};
</script>

<style lang="scss" scoped>
$yellow: rgb(242, 200, 17);
.process_box {
  .el-col-4 {
    width: 18.75%;
  }
  .process_li {
    width: 100%;
    height: 68px;
    background: rgb(222, 222, 222);
    color: rgb(120, 120, 120);
    font-size: 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    /* 水平居中*/
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-pack: center; /* IE 10 */
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center; /* IE 11+,Firefox 22+,Chrome 29+,Opera 12.1*/
    /* 垂直居中 */
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-align: center; /* IE 10 */
    -webkit-align-items: center;
    -moz-align-items: center;
    .icon {
      width: 24px;
      height: 24px;
      line-height: 24px;
      vertical-align: middle;
      background: rgb(120, 120, 120);
      color: #ffffff;
      border-radius: 50%;
    }
    .text {
      vertical-align: middle;
      margin: 0 5px;
    }
  }
  .process_icon {
    .iconfont {
      font-weight: bold;
      font-size: 21px;
      color: rgb(120, 120, 120);
      line-height: 68px;
    }
  }
  .active {
    background: $yellow;
    .text {
      color: #000000;
    }
    .icon {
      background: #000000;
    }
  }
}
</style>
