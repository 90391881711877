<template>
  <div class="norm_info_box">
    <el-form
      :model="dynamicValidateForm"
      ref="dynamicValidateForm"
      label-width="200px"
      class="demo-dynamic"
    >
      <div
        class="norm_list"
        v-for="(domain, index) in dynamicValidateForm.domains"
        :key="index"
      >
        <span v-if="lock">
          <span>
            <el-form-item :label="'企业标准代号'" v-if="domain.value">
              <span class="black">{{ domain.value }}</span>
            </el-form-item>
            <el-form-item :label="'企业标准文件'" v-if="domain.value">
              <div class="up_file_li">
                <div class="file_info">
                  <a :href="domain.file" target='_blank' download>
                    <el-button size="small" type="text">{{
                      domain.name
                    }}</el-button>
                  </a>
                </div>
              </div>
            </el-form-item>
          </span>
        </span>
        <span v-else>
          <div class="clr remove_btn">
            <el-button
              icon="el-icon-delete"
              type="text"
              class="fr"
              circle
              @click.prevent="removeDomain(domain)"
            ></el-button>
          </div>
          <el-form-item
            :label="'企业标准代号'"
            :key="domain.key"
            :prop="'domains.' + index + '.value'"
            :rules="{}"
          >
            <span class="black" v-if="lock">{{ domain.value }}</span>
            <el-input v-else v-model="domain.value"></el-input>
          </el-form-item>
          <el-form-item :label="'企业标准文件'">
            <div class="up_file_li">
              <div class="file_info">
                <el-upload
                  :action="uploadUrl"
                  :data="{ type: 4 }"
                  accept=".txt, .word, .pdf, .doc, .docx"
                  :on-success="
                    (res) => {
                      return handleSuccess(res, domain);
                    }
                  "
                  :before-upload="beforeUpload"
                  :on-remove="
                    (res) => {
                      return handleRemove(res, domain);
                    }
                  "
                  :http-request="uploadRequest"
                  :on-preview="(file)=>file.response&& open(file.response.data.url, '_blank')"
                  :file-list="domain.refile"
                >
                  <el-button size="small" type="primary">{{
                    domain.name ? "重新上传" : "点击上传"
                  }}</el-button>
                  <div slot="tip" class="el-upload__tip hint">
                    请上传备案成功后的标准文件。
                    <br />格式要求：支持.pdf .word
                    .txt格式文件，文件大小不超过100M。
                  </div>
                </el-upload>
              </div>
            </div>
          </el-form-item>
        </span>
      </div>

      <div v-if="!lock">
        <el-button type="text" @click="addDomain">添加更多企标</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { upload } from "@/assets/js/validate";
import { mapGetters } from "vuex";
export default {
  props: ["norm_list"],
  data() {
    return {
      uploadUrl: "https://upload.qiniup.com/",
      file: {},
      dynamicValidateForm: {
        domains: [],
      },
    };
  },
  computed: {
    lock() {
      return this.$store.state.common.apply;
    },
    ...mapGetters(["user"]),
  },
  mounted() {
    setTimeout((res) => {
      let list = this.$store.getters.firm_list;
      if (list) {
        if (list.companyType !== 1) {
          this.dynamicValidateForm.domains = [];
          if (list.enterpriseStandards.length === 0) {
            this.dynamicValidateForm.domains.push({
              value: "",
              file: "",
              name: "",
              list: "",
              refile: [],
            });
          } else {
            list.enterpriseStandards.forEach((e) => {
              this.dynamicValidateForm.domains.push({
                value: e.standardNumber,
                file: e.standardFile.url,
                name: e.standardFile.originalname,
                list: e.standardFile,
                refile: [
                  {
                    name: e.standardFile.originalname,
                  },
                ],
              });
            });
          }
        }
      } else {
        this.dynamicValidateForm.domains.push({
          value: "",
          file: "",
          name: "",
          list: "",
          refile: [],
        });
      }
    }, 0);
  },
  methods: {
    submitForm() {
      return this.dynamicValidateForm;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    removeDomain(item) {
      var index = this.dynamicValidateForm.domains.indexOf(item);
      if (index === 0) {
        this.dynamicValidateForm.domains.splice(0, 1);
      } else {
        if (index !== -1) {
          this.dynamicValidateForm.domains.splice(index, 1);
        }
      }
    },
    addDomain() {
      this.dynamicValidateForm.domains.push({
        value: "",
        file: "",
        name: "",
        list: "",
        refile: [],
      });
    },
    // 文件上传这里开始
    handleSuccess(response, i) {
      const url = response;
      this.file = url;
      i.file = url.data.url;
      i.name = url.data.originalname;
      i.list = url.data;
      i.refile = [
        {
          name: url.data.originalname,
        },
      ];
    },
    uploadRequest(request) {
      request.user = this.user;
      request.type = 4;
      let that = this;
      that.$api
        .upload_get({
          type: request.type,
        })
        .then((res) => {
          let token = res.data.token;
          let host = res.data.domain;
          upload(
            token,
            request,
            (next) => {
              let total = next.total;
              request.file.percent = next.total.percent;
              request.onProgress(request.file);
            },
            (error) => {
              console.log(error);
            },
            (complete) => {
              let hash = complete.hash;
              let key = complete.key;
              let data = {
                data: complete,
              };
              request.onSuccess(data);
            }
          );
        });
    },
    beforeUpload(file) {
      let fileName = file.name;
      let regex = /(.pdf|.word|.txt|.doc|.docx)$/;
      if (!regex.test(fileName.toLowerCase())) {
        this.$message.error("该文件类型暂未支持,请选中正确文件!");
        return false;
      }
      const imageSize = file.size / 1024 / 1024 < 100; //上传图片大小不超过1M
      if (!imageSize) {
        this.$message.error("上传文件大小不能超过 100MB!");
        return false;
      }
      return imageSize;
    },
    handleRemove(file, arr) {
      arr.file = "";
      arr.name = "";
      arr.list = "";
      arr.refile = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.norm_info_box {
  .norm_list {
    .remove_btn {
      .el-button {
        font-size: 20px;
      }
    }
    .up_file_li {
      .hint {
        font-size: 12px;
        color: #606266;
        line-height: 1.5;
      }
      .file_info {
      }
    }
  }
}
</style>
