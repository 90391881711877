<template>
  <div>
    <el-upload
      class="image-uploader"
      action="https://upload.qiniup.com"
      :data="{ type: 2 }"
      :limit="limit"
      :file-list="fileList"
      :before-upload="onLimitFile"
      :on-exceed="onLimitNumber"
      :http-request="uploadRequest"
      :on-success="handleSuccess"
      :on-remove="onRemove"
      :list-type="listType"
      :accept="accept"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">
        {{ tip }}
      </div>
    </el-upload>
  </div>
</template>
<script>
import { upload } from "@/assets/js/validate";
import { mapGetters } from "vuex";
export default {
  props: {
    accept:{},
    listType: {
      default: "picture",
    },
    value: {
      type: Array,
    },
    tip: {},
    maxSize: {
      default: 10,
    },
  },
  watch: {
    value: {
      handler() {
        this.fileList = this.value || [];
      },
      immediate: true,
    },
    fileList() {
      this.$emit("input", this.fileList);
      this.$emit("change", this.fileList);
    },
  },
  data() {
    return {
      fileList: [],
      limit: 10,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    onLimitFile(file) {
      const imageSize = file.size / 1024 / 1024 < this.maxSize;
      if (imageSize) return true;
      this.$message.error(`此文件 [ ${file.name} ] 大小超出10M 。`);
      return false;
    },
    onLimitNumber(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    async uploadRequest(request) {
      const uploadConfig = await this.$api.upload_get({
        type: request.data.type,
      });
      const { token } = uploadConfig.data;
      upload(
        token,
        {
          file: request.file,
          user: this.user,
          type: request.data.type,
        },
        (next) => {
          request.file.percent = next.total.percent;
          request.onProgress(request.file);
        },
        (error) => {
          console.log(error, request);
          this.$message.error(`七牛上传失败：${error.message}`);
          request.onError(error);
        },
        request.onSuccess
      );
    },
    handleSuccess(data) {
      if (!data || !data.url) return;
      data.name = data.originalname;
      this.fileList.push(data);
    },
    onRemove(file, fileList) {
      this.fileList = fileList;
    },
  },
};
</script>